/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.sk-post-text,
.sk-post-text-new,
.post-post-counts {
  margin: 15px;
}

.sk-popup-details .sk-post-text,
.sk-popup-details .sk-post-text-new {
  margin: 0;
  padding: 15px;
}

.sk_branding {
  visibility: hidden;
}

.scalable-section.ua-head {
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 1) 50%,
    rgba(19, 41, 25, 1) 100%
  );
}

.careers-link {
  background: -webkit-linear-gradient(
    344.76deg,
    #8cdaa4 6.22%,
    #53b36f 97.05%
  ) !important;

  .arrow {
    margin-top: -4px;
    margin-left: 8px;
  }
}

.about-company .numbers-block .number-container {
  background-color: rgba(#53b36f, 0.3) !important;
  margin: 10px;

  .number {
    color: #53b36f !important;
  }
}

.values-item .value-content .icon {
  width: 60px;
  margin-bottom: 15px;
}

.our-team-localized .team-members-container {
  justify-content: center;

  .all-team {
    width: 100%;
    margin-bottom: 40px;
    filter: grayscale(1);
    border-radius: 18px;
    object-fit: cover;
    padding: 7px;
  }
}

.book-a-call-2 {
  width: 292px;
  height: 94px;
  color: #000000 !important;
  font-size: 24px;
  line-height: 94px;
  text-align: center;
  text-decoration: none;
  background: linear-gradient(344.76deg, #8cdaa4 6.22%, #53b36f 97.05%);
  border-radius: 26px;
  text-decoration: none !important;
  cursor: pointer;
}

.awards {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 30px;

  img {
    width: 100px;
  }
}

.media-about-us-localized {
  height: unset !important;
}

.calculator-bg {
  background-color: #fafafa;
}

@media screen and (min-width: 825px) {
  .awards {
    margin-top: -100px;
  }
}

@media (max-width: 767px) {
  .book-a-call-2 {
    width: 200px;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
  }
}
