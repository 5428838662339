header {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-flow: column;
  align-items: center;
  width: calc(150px * 4 + 10px * 4 + 40px);
  padding: 10px 20px;
  background-color: #1f1f1f;
  color: white;
  border-radius: 16px;
  font-family: 'Outfit', Sans-Serif;
  font-size: 20px;
  z-index: 10000;
  transition: opacity 2s;
  cursor: pointer;

  &.hiden-calculator-result {
    width: unset;
  }

  .calculator-result {
    display: flex;
    flex-flow: column;
    align-items: center;
    border-radius: 5px;
    color: white;
    transition: opacity 2s;

    &.hiden-calculator-result {
      visibility: hidden;
      opacity: 0;
      width: 0;
      height: 0;
    }

    p {
      margin: 0;
    }

    .result-row {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 0;

      .service {
        width: 150px;
        margin: 5px;
        padding: 5px;
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 5px;

        .title {
          display: flex;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }

        .price {
          font-size: 20px;
        }
      }
    }

    .total-cost {
      flex: 1;
      display: flex;
      margin: 5px;

      p {
        font-size: 25px;

        &:nth-child(2) {
          margin-left: 10px;
          color: #53b36f;
        }
      }
    }

    .discuss {
      flex: 1;
      height: 40px;
      margin: 5px;
      background-color: #53b36f;
      color: white;
      text-align: center;
      line-height: 40px;
      font-size: 20px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 700px) {
  header {
    width: calc(150px * 2 + 10px * 2 + 40px);

    .result-row:nth-child(2) {
      flex-flow: column-reverse;
      padding: 0 5px;
    }

    .total-cost {
      justify-content: center;
    }

    .discuss {
      width: 100%;
    }
  }
}

@media screen and (max-width: 380px) {
  header {
    top: -15px;
    transform: translateX(-50%) scale(0.8);
  }
}
